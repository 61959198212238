import React, { useState } from "react";
import {
  Page,
  Text,
  Font,
  Image,
  Document,
  StyleSheet,
  View,
} from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import logo from "../imboden-pdf.png";

Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 15,
    marginBottom: 35,
  },
  address: {
    fontSize: 12,
    marginBottom: 40,
  },
  datum: {
    fontSize: 12,
    marginBottom: 40,
  },
  datumLabel: {
    fontSize: 9,
  },
  anrede: {
    fontSize: 12,
    marginBottom: 10,
  },
  text: {
    fontSize: 12,
    textAlign: "justify",
  },
  image: {
    width: 150,
  },
  header: {
    fontSize: 12,
    color: "grey",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 15,
    marginBottom: 60,
    borderBottom: "1px solid black",
  },
  titleHeader: {
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "right",
  },
  table: {
    marginTop: 50,
    borderTop: "2px solid #fbbb00",
  },
  tableHeader: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "lightgrey",
    alignItems: "center",
  },
  label: {
    fontSize: 12,
    fontWeight: "bold",
  },
  value: {
    fontSize: 12,
  },
  tableCell1: {
    width: "20%",
    paddingTop: 10,
    paddingBottom: 10,
  },
  tableCell2: {
    width: "70%",
    paddingTop: 10,
    paddingBottom: 10,
  },
  tableCell3: {
    width: "10%",
    paddingTop: 10,
    paddingBottom: 10,
  },
  tableRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

// Create Document Component
const MyDocument = (props) => (
  <Document>
    <Page style={styles.body}>
      <View style={styles.header} fixed>
        <Image style={styles.image} src={logo} />
      </View>
      <View style={styles.address}>
        <Text>
          {props.data.name} {props.data.vorname}
        </Text>
        <Text>{props.data.kontostelle}</Text>
        <Text>{props.data.email}</Text>
      </View>
      <Text style={styles.datum}>Raron, {new Date().toLocaleDateString()}</Text>
      <Text style={styles.title}>
        Lieferschein-Nr: {new Date().getFullYear()}-{props.data.kunden_id}
      </Text>
      <Text style={styles.anrede}>Sehr geehrte Damen und Herren.....</Text>
      <Text style={styles.text}>
        Hechas, pues, estas prevenciones, Hechas, pues, estas prevenciones, no
        quiso aguardar más tiempo a poner en efeto su pensamiento, apretándole a
        ello la falta que él pensaba que hacía en el mundo su tardanza.
      </Text>
      <View style={styles.table}>
        <View style={styles.tableHeader}>
          <Text style={[styles.tableCell1, styles.label]}>Position</Text>
          <Text style={[styles.tableCell2, styles.label]}>Maschine</Text>
          <Text style={[styles.tableCell3, styles.label]}>Menge</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell1, styles.value]}>1</Text>
          <Text style={[styles.tableCell2, styles.value]}>
            {props.data.maschinen_name} {"\n"}
            <Text style={styles.datumLabel}>
              Seriennummer: {props.data.seriennummer}
            </Text>{" "}
            {"\n"}
            <Text style={styles.datumLabel}>
              {props.data.startdatum.toLocaleDateString()}
              {/*:{props.data.startdatum.toLocaleTimeString()}*/} -{" "}
              {props.data.enddatum.toLocaleDateString()}
              {/*:{props.data.enddatum.toLocaleTimeString()}*/}
            </Text>
          </Text>
          <Text style={[styles.tableCell3, styles.value]}>1 Stück</Text>
        </View>
      </View>
      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        fixed
      />
    </Page>
  </Document>
);

const PDFDocument = (props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      {console.log(props.data)}
      <Button variant="primary" onClick={handleShow}>
        <i className="bx bxs-file-pdf"></i> Lieferschein
      </Button>
      <Modal
        show={show}
        fullscreen={true}
        onHide={handleClose}
        backdrop="static"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Lieferschein für: {props.data.name + " " + props.data.vorname}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PDFViewer width={"100%"} height={"99%"}>
            <MyDocument data={props.data} />
          </PDFViewer>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PDFDocument;
