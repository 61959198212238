import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import React, { useState, useEffect } from "react";
import ModalConfirm from "./ModalConfirm";

const ModalMachineSettings = (props) => {
    const [show, setShow] = useState(false);

    // Track the confirmation message => ModalConfirm
    const [message, setMessage] = useState("");

    const [valuesMaschine, setValuesMaschine] = useState({
        seriennummer: props.serialnumber_status.serial_number,
        status: props.serialnumber_status.serial_number_status,
    });


    const handleClose = () => setShow(false);
    const handleShow = (e) => {
        e.preventDefault();
        setShow(true);
        setMessage("");
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValuesMaschine((prevMaschine) => ({
            ...prevMaschine,
            [name]: value,
        }));
    }

    const handleMessage = (message) => (e) => {
        e.preventDefault();
        setMessage(message);
        setShow(false);
    }

    return (
        <>

            <Button variant="primary" onClick={handleShow}>
                {props.text}
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.data.name_maschine} | {props.serialnumber_status.serial_number}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                            <Form.Label>Status</Form.Label>
                            <Form.Select defaultValue={''} name="status" onChange={handleChange} required>
                                <option value="" disabled >Status wählen:</option>
                                <option value="2">verfügbar</option>
                                <option value="4">werkstatt</option>
                                <option value="5">service</option>
                            </Form.Select>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {/* if click setMessage to "Wollen Sie die Maschine wirklich löschen?"*/}
                    <Button  variant="danger" onClick={handleMessage("Wollen Sie die Maschine wirklich löschen?")}>
                        Löschen
                    </Button>
                    <Button variant="primary" onClick={handleMessage("Wollen Sie den Status wirklich ändern?")}>
                        Speichern
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Render the confirmation message based on the confirmMessage state */}
            {message &&(
                <ModalConfirm text={message} buttonText={"Bestätigen"} id={props.data.machine_id} seriennummer={props.serialnumber_status.serial_number} status={valuesMaschine}/>
            )}
        </>
    );
};

export default ModalMachineSettings;