import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {saveNeueMaschine} from "../FunctionsAPI";
import ModalResult from "./ModalResult";

const DashAddMachine = () => {
  const [newMachine, setNewMachine] = useState({
    name: '',
    seriennummer: '',
    hoehe: '',
    maschinentyp: '',
    antriebsart: '',
    tragkraft: '',
    arbeitsreichweite: ''
  });

  // if true, show ModalResult for to inform user that machine was added
  const [showModal, setShowModal] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewMachine((prevMachine) => ({
      ...prevMachine,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowModal(true);
    console.log(newMachine);
    saveNeueMaschine(newMachine);
    console.log("save");
  };

  return (
    <>
        <h3 className="dashTitle">Neue Maschine hinzufügen</h3>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="maschinenname">
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" placeholder="Maschinenname" name="maschinenname" onChange={handleChange} required/>
          </Form.Group>
          <Form.Group className="mb-3" controlId="seriennummer">
            <Form.Label>Seriennummer</Form.Label>
            <Form.Control type="text" placeholder="Seriennummer" name="seriennummer" onChange={handleChange} required/>
          </Form.Group>
          <Form.Group className="mb-3" controlId="hoehe">
            <Form.Label>Höhe</Form.Label>
            <Form.Control type="float" placeholder="Höhe" name="hoehe" onChange={handleChange} required/>
          </Form.Group>
          <Form.Group className="mb-3" controlId="maschinentyp">
            <Form.Label>Maschinentyp</Form.Label>
            <Form.Select defaultValue={''} aria-label="Default select example" name="maschinentyp" onChange={handleChange} required>
              <option value="" disabled>Maschinentyp wählen</option>
              <option value="Scherenbühne">Scherenbühne</option>
              <option value="Teleskopbühne">Teleskopbühne</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3" controlId="antriebsart">
            <Form.Label>Antriebsart</Form.Label>
            <Form.Select defaultValue={''} aria-label="Default select example" name="antriebsart" onChange={handleChange} required>
              <option value="" disabled>Antriebsart wählen</option>
              <option value="3">Diesel</option>
              <option value="4">Elektro</option>
              <option value="5">Hybrid</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3" controlId="tragkraft">
            <Form.Label>Tragkraft</Form.Label>
            <Form.Control type="float" placeholder="Tragkraft" name="tragkraft" onChange={handleChange} required/>
          </Form.Group>
          <Form.Group className="mb-3" controlId="arbeitsreichweite">
            <Form.Label>Arbeitsreichweite</Form.Label>
            <Form.Control type="float" placeholder="-" name="arbeitsreichweite" onChange={handleChange}/>
          </Form.Group>
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
        {showModal && (
            <ModalResult
                text={"Maschine wurde hinzugefügt"} buttonText={"Schliessen"}
            />
        )}
      </>
  );
};

export default DashAddMachine;
