import { auth } from "../initFirebase";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SideBar from "../components/SideBar";
import DashCalendar from "../components/DashCalendar";
import DashPieChart from "../components/DashPieChart";
import DashLineChart from "../components/DashLineChart";
import DashBarChart from "../components/DashBarChart";

export default function Statistik() {
  const navigate = useNavigate();
  useEffect(() => {
    if (!auth.currentUser) {
      navigate("/");
      return;
    }
  }, []);

  return (
    <>
      <SideBar />
      <section className="home-section">
        <Container>
          <Row>
            <Col>
              <DashBarChart />
            </Col>
            <Col>
              <DashLineChart />
            </Col>
          </Row>
          <Row>
            <Col>
              <DashPieChart />
            </Col>
            <Col className="bg-black">3 of 3</Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
