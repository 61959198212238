import Modal from "react-bootstrap/Modal";
import React, {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import {deleteMaschine, deleteAnfrage, updateStatusMaschine, handleEmail, deleteVermietung} from "./../FunctionsAPI";
import ModalResult from "./ModalResult";


const ModalConfirm = (props) => {
    const [show, setShow] = useState(true);

    const [showModal, setShowModal] = useState(false);
    // Track the confirmation message => ModalResult
    const [message, setMessage] = useState("");

    const handleClose = () => {
        setShow(false);
        if (props.handleMessage) {
            props.handleMessage("");
            console.log(props.idVermietung)
        }
    }

    const handleAction = () => {
        switch (props.text) {
            case "Wollen Sie den Status wirklich ändern?":
                updateStatusMaschine(props.status);
                setMessage("Status wurde geändert")
                break;
            case "Wollen Sie die Anfrage wirklich ablehnen?" :
                handleEmail(props.email, "ABGELEHNT");
                deleteAnfrage(props.idVermietung);
                setMessage("Anfrage wurde gelöscht");
                break;
            case "Wollen Sie die Maschine wirklich löschen?" :
                deleteMaschine(props.seriennummer, props.id);
                setMessage("Maschine wurde gelöscht");
                break;
            case "Wollen Sie die Reservierung wirklich löschen?" :
                handleEmail(props.email, "ABGELEHNT");
                deleteVermietung(props.idVermietung);
                setMessage("Reservierung wurde gelöscht");
                break;
        }

        setShowModal(true);
        setShow(false);
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{props.text}</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                <Button variant="primary" onClick={handleAction} >
                    {props.buttonText}
                </Button>
                </Modal.Footer>
            </Modal>
            {showModal &&(
                <ModalResult text={message} buttonText="Schliessen"/>
            )}
        </>
    );
};

export default ModalConfirm;