import React, { useEffect, useState } from "react";
import ModalFormVermietung from "./ModalFormVermietung";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import { getAlleOffenenAnfragen } from "../FunctionsAPI";
import ModalConfirm from "./ModalConfirm";

const DashAllAnfragen = () => {
  const [data, setData] = useState([]);
  // Track the confirmation message => ModalConfirm
  const [message, setMessage] = useState("");
  const [idVermietung, setIdVermietung] = useState(0);
  const [email, setEmail] = useState("");

  useEffect(() => {
    getAlleOffenenAnfragen().then((anfragen) => setData(anfragen));
  }, []);

  function formatDateTime(date) {
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
      timeZone: "Europe/Zurich",
    };

    return new Intl.DateTimeFormat("de-CH", options).format(date);
  }

  // pass this function to ModalConfirm => set message to ""
  // for re-opening ModalConfirm
  const handleMessage = (message) => (e) => {
    setMessage(message);
  }

  // get id and email from right user/vermietung and set message
  const passDataToModal = (id,email) => (e) => {
      setIdVermietung(id);
      setEmail(email);
      setMessage("Wollen Sie die Anfrage wirklich ablehnen?");
  }

  return (
    <>
      <h3 className="dashTitle">Anfragen</h3>
      {data
        .sort((a, b) => new Date(a.startdatum) - new Date(b.startdatum))
        .map((anfrage, index) => (
          <Accordion key={index}>
            <Accordion.Item eventKey={index} key={index}>
              <Accordion.Header>
                {anfrage.name + " " + anfrage.vorname}
              </Accordion.Header>
              <Accordion.Body>
                <ListGroup>
                  <ListGroup.Item>
                    <strong>Von:</strong>{" "}
                    {formatDateTime(new Date(anfrage.startdatum))}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <strong>Bis:</strong>{" "}
                    {formatDateTime(new Date(anfrage.enddatum))}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <strong>Gebrauchte Höhe:</strong> {anfrage.gebrauchteHoehe}{" "}
                    m
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <strong>Gebrauchte Antriebsart:</strong>{" "}
                    {anfrage.gebrauchteAntriebsart}
                  </ListGroup.Item>
                </ListGroup>
                <br />
                <div className="accordionFooter">
                  <ModalFormVermietung data={anfrage} text="Vermieten" />
                  <Button
                    variant="danger"
                    onClick={passDataToModal(anfrage.id_vermietungen,anfrage.email)}
                  >
                    Ablehnen
                  </Button>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        )
        )}
      {message  && (
          <ModalConfirm email={email} idVermietung={idVermietung} text={message} buttonText={"Bestätigen"} handleMessage={handleMessage()}
            />
        )}
    </>
  );
};

export default DashAllAnfragen;
