import React, { useEffect, useState } from "react";
import Badge from "react-bootstrap/Badge";
import Table from "react-bootstrap/Table";
import Accordion from "react-bootstrap/Accordion";
import { getAlleMaschinenGruppiert } from "../FunctionsAPI";
import ModalMachineSettings from "./ModalMachineSettings";

const DashAllMachines = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getAlleMaschinenGruppiert().then((data) => setData(data));
    //getAllStatusMachines().then((data) => setStatus(data));
  }, []);

  return (
    <>
      <div>
        <h3 className="dashTitle">Alle Maschinen</h3>
        {data.map((maschine, index) => (

          <Accordion key={index} >
            <Accordion.Item eventKey={index}>
              <Accordion.Header>{maschine.name_maschine}</Accordion.Header>
              <Accordion.Body >
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Seriennummern</th>
                      <th>Status</th>
                      <th>Bearbeiten</th>
                    </tr>
                  </thead>
                  <tbody>
                    {maschine.serial_numbers.map((serialNumber, subIndex) => (
                      <tr key={subIndex}>
                        <td>{serialNumber.serial_number}</td>
                        <td>
                          <Badge
                            bg={
                              serialNumber.serial_number_status == "verfügbar"
                                ? "success"
                                : "danger"
                            }
                            pill
                            className="badge-maschinen"
                          >
                            {serialNumber.serial_number_status}
                          </Badge>
                        </td>
                        <td>
                            <ModalMachineSettings data={maschine} serialnumber_status={serialNumber}  text="settings"/>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        ))}
      </div>
    </>
  );
};

export default DashAllMachines;
