import { auth } from "../initFirebase";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SideBar from "../components/SideBar";
import DashCalendar from "../components/DashCalendar";

export default function Dashboard() {
  const navigate = useNavigate();
  useEffect(() => {
    if (!auth.currentUser) {
      navigate("/");
      return;
    }
  }, []);

  return (
    <>
      <SideBar />
      <section className="home-section">
        <Container>
          <Row>
            <Col id="calender">
              <DashCalendar />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
