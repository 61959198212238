import Modal from "react-bootstrap/Modal";
import React, {useState} from "react";
import Button from "react-bootstrap/Button";

const ModalResult = (props) => {
    const [show, setShow] = useState(true);

    const handleClose = () => {
        setShow(false)
        window.location.reload();
    };

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.text}</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose} >
                        {props.buttonText}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalResult;