import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./initFirebase";
import React, { useEffect, useState } from "react";
import Logout from "./pages/Logout";
import "bootstrap/dist/css/bootstrap.min.css";
import Spinner from "react-bootstrap/Spinner";

import { Route, Routes } from "react-router-dom";
import ErrorPage from "./pages/error-page";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Dashboard from "./pages/Dashboard";
import Kalender from "./pages/Kalender";
import Maschinen from "./pages/Maschinen";
import { Container, Row } from "react-bootstrap";
import SideBar from "./components/SideBar";
import Statistik from "./pages/Statistik";

export default function App() {
  /* Current user state */
  const [currentUser, setCurrentUser] = useState(undefined);

  /* Watch for authentication state changes */
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      console.log("User is", user);
      setCurrentUser(user);
    });

    // Unsubscribe from changes when App is unmounted
    return () => {
      unsubscribe();
    };
  }, []);

  if (currentUser === undefined) {
    return (
      <div className="App">
        <SideBar />
        <div className="home-section">
          <Container
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "90vh",
            }}
          >
            <Row>
              <Spinner
                animation="border"
                style={{
                  color: "#fbbb00",
                  height: "100px",
                  width: "100px",
                }}
              />
            </Row>
          </Container>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home currentUser={currentUser} />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/kalender" element={<Kalender />} />
          <Route path="/statistik" element={<Statistik />} />
          <Route path="/maschinen" element={<Maschinen />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </div>
    </>
  );
}
