import { Link } from "react-router-dom";
import Dashboard from "./Dashboard";
import Login from "./Login";

export default function Home({ currentUser }) {
  return (
    <div>
      {!currentUser ? (
        <>
          <Login />
        </>
      ) : (
        <Dashboard />
      )}
    </div>
  );
}
