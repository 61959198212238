import data from "bootstrap/js/src/dom/data";

const BASE_URL =
  "https://vermietung-raron.ch/vermietung/backend/API.php?action=";

const fetchData = async (action, method = "GET", bodyData = null) => {
  try {
    const url = BASE_URL + action;
    const options = {
      method,
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (bodyData) {
      options.body = JSON.stringify(bodyData);
    }

    const response = await fetch(url, options);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const saveNeueMaschine = async (data) => {
    try {
        const response = await fetchData("saveNeueMaschine", "POST", data);
        console.log("Response data:", response);
        return response;
    } catch (error) {
        console.error("Error:", error);
    }
}

export const deleteMaschine = async (seriennummer, id) => {
    try {
        const data = {seriennummer: seriennummer, id: id};
        const response = await fetchData("deleteMaschine", "POST", data);
        console.log("Response data:", response);
        return response;
    } catch (error) {
        console.error("Error:", error);
    }
}


export const updateStatusMaschine = async (maschine) => {
    try {
        const data = { seriennummer: maschine.seriennummer ,status : maschine.status};
        const response = await fetchData("updateStatusMaschine", "POST", data);
        console.log("Response data:", response);
        return response;
    } catch (error) {
        console.error("Error:", error);
    }
}

/*export const getMaschine = async (id) => {
    try {
        const data = { id: id };
        const response = await fetchData("getMaschine", "POST", data);
        console.log("Response data:", response);
        return response;
    } catch (error) {
        console.error("Error:", error);
    }
}*/



export const sendTestData = async () => {
  try {
    const data = { name: "Testmaschine" };
    const response = await fetchData("getTest", "POST", data);
    console.log("Response data:", response);
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getAlleMaschinen = async () => {
  try {
    return await fetchData("getAlleMaschinen");
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getAlleRessourcen = async () => {
  try {
    return await fetchData("getAlleRessourcen");
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getAlleMaschinenGruppiert = async () => {
  try {
    const response = await fetchData("getAlleMaschinenGruppiert");

    // Parse the 'serial_numbers' field for each machine in the data
    const machinesWithSerialNumbers = response.map((machine) => ({
      ...machine,
      serial_numbers: JSON.parse(machine.serial_numbers),
    }));

    return machinesWithSerialNumbers;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getAlleOffenenAnfragen = async () => {
  try {
    return await fetchData("getAlleOffenenAnfragen");
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getAlleAnfragen = async () => {
  try {
    return await fetchData("getAlleAnfragen");
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getAlleVermietungen = async () => {
  try {
    return await fetchData("getAlleVermietungen");
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getKorrekteMaschinen = async (
  endDatum,
  gebrauchteHoehe,
  gebrauchteAntriebsart
) => {
  try {
    const data = {
      endDatum: endDatum,
      gebrauchteHoehe: gebrauchteHoehe,
      gebrauchteAntriebsart: gebrauchteAntriebsart,
    };
    const response = await fetchData("getKorrekteMaschinen", "POST", data);
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};
export const deleteAnfrage = async (data) => {
    try {
        const idVermietung = { id: data };
        const response = await fetchData("deleteAnfrage", "POST", idVermietung);
        console.log("Response data:", response);
        return response;
    } catch (error) {
        console.error("Error:", error);
    }
}

export const deleteVermietung = async (data) => {
  try {
      const idVermietung = { id: data };
    const response = await fetchData("deleteVermietung", "POST", idVermietung);
    console.log("Response data:", response);
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const handleEmail = async (email, nachricht) => {
  try {
      console.log(email);
        console.log(nachricht);
    const data = {
      email: email,
      message: nachricht,
    };
    const response = await fetchData("neueMail", "POST", data);
    console.log(response);
  } catch (error) {
    console.error("Error:", error);
  }
};

export const saveNeueVermietung = async (data) => {
  try {
    const response = await fetchData("saveNeueVermietung", "POST", data);
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const updateVermietungZeit = async (data) => {
  try {
    const response = await fetchData("updateVermietungZeit", "POST", data);
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getAllAnfragen = async () => {
  return fetch("https://zenmail.ch/app/abfragen/getAllAnfragen.php")
    .then((response) => response.json())
    .then((data) => data)
    .catch((error) => console.error(error));
};

export const updateMachines = async (data) => {
  console.log(data);

  return fetch("https://zenmail.ch/app/abfragen/updateStatusMachines.php", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      id: data.id,
      status: data.status_maschine_id,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => data)
    .catch((error) => error);
};

export const updateStatusMachines = async () => {
  return fetch("https://zenmail.ch/app/abfragen/updateMachines.php")
    .then((response) => response.json())
    .then((data) => data)
    .catch((error) => console.error(error));
};

export const getAllMachines = async () => {
  return fetch("https://zenmail.ch/app/abfragen/getAllMachines.php")
    .then((response) => response.json())
    .then((data) => data)
    .catch((error) => console.error(error));
};

export const getAllStatusMachines = async () => {
  return fetch("https://zenmail.ch/app/abfragen/getAllStatusMachines.php")
    .then((response) => response.json())
    .then((data) => data)
    .catch((error) => console.error(error));
};

export const getAllMachinesToCheck = async (startdatum, enddatum) => {
  console.log(startdatum);

  return fetch("https://zenmail.ch/app/abfragen/getAllMachinesVermietung.php", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },

    body: JSON.stringify({
      start: startdatum,
      end: enddatum,
    }),
  })
    .then((response) => response.json())
    .then((data) => data)
    .catch((error) => console.error(error));
};
