import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import React, { useState, useEffect } from "react";
import { getKorrekteMaschinen, saveNeueVermietung,handleEmail } from "./../FunctionsAPI";

const ModalFormVermietung = (props) => {
  const [response, setResponse] = useState(null);
  const [show, setShow] = useState(false);
  const [machines, setMachines] = useState([]);
  const [valuesVermietung, setValuesVermietung] = useState({});
  const [idVermietung, setIdVermietung] = useState(props.data.id_vermietungen);
  const [selectedMachine, setSelectedMachine] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleUpdateVermietung = (e) => {
    e.preventDefault();

    const updatedValuesVermietung = {
      ...valuesVermietung,
      id_maschine_seriennummer: selectedMachine,
      id_vermietungen: idVermietung,
    };

    console.log(updatedValuesVermietung);

    saveNeueVermietung(updatedValuesVermietung).then((data) =>
      setResponse(data)
    );

    handleEmail(props.data.email, "Ihre Anfrage wurde bestätigt");
    setShow(false);
  };



  useEffect(() => {
    /*getAllMachinesToCheck(props.data.startdatum, props.data.enddatum).then(
      (data) => console.log(data)
    );*/
    getKorrekteMaschinen(
      props.data.enddatum,
      props.data.gebrauchteHoehe,
      props.data.gebrauchteAntriebsart
    ).then((data) => setMachines(data));
  }, []);

  useEffect(() => {
    console.log(response);
    if (response) {
      window.location.reload();
    }
  }, [response]);

  return (
    <>
      <Button variant="success" onClick={handleShow}>
        {props.text}
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {props.data.name} {props.data.vorname}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {machines.length > 0 ? (
            <Form.Select
                name="id_maschine_seriennummer"
                aria-label="Default select example"
                defaultValue={''}
                onChange={(e) => {
                  setSelectedMachine(e.target.value);
                  setIdVermietung(props.data.id_vermietungen);
                }}
            >
              <option value="" disabled>
                Verfügbare Maschinen:
              </option>
              {machines.map((maschine, index) => (
                <option value={maschine.id_maschine_seriennummer} key={index}>
                  {maschine.name_maschine} | {maschine.seriennummer}
                </option>
              ))}
            </Form.Select>
          ) : (
            "Keine Verfügbar"
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={(event) => handleUpdateVermietung(event)}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalFormVermietung;
