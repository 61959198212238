import React, { useState } from "react";

import { Link } from "react-router-dom";

export default function SideBar() {
  const [isOpen, setIsOpen] = useState(true);
  const closeBtnRef = React.useRef(null);

  const handleClick = () => {
    setIsOpen(!isOpen);
    menuBtnChange();
  };

  const menuBtnChange = () => {
    if (isOpen) {
      closeBtnRef.current.classList.replace("bx-menu", "bx-menu-alt-right");
    } else {
      closeBtnRef.current.classList.replace("bx-menu-alt-right", "bx-menu");
    }
  };

  return (
    <>
      <div className={`sidebar ${isOpen ? "open" : ""}`}>
        <div className="logo-details">
          <img
            className="icon"
            src="https://zenmail.ch/static/media/imboden-schnitt.4a1a9a5655c8e87d9e54.png"
            height="25px"
            style={{ marginRight: "20px" }}
          ></img>
          <div className="logo_name">Imboden Baumaschinen</div>
          <i
            className="bx bx-menu"
            id="btn"
            ref={closeBtnRef}
            onClick={handleClick}
          ></i>
        </div>
        <ul className="nav-list">
          {/*<li>
            <i
              className="bx bx-search"
              ref={closeBtnRef}
              onClick={handleClick}
            ></i>
            <input type="text" placeholder="Search..." />
            <span className="tooltip">Search</span>
          </li>*/}
          <li>
            <Link to="/dashboard">
              <i className="bx bx-grid-alt"></i>
              <span className="links_name">Dashboard</span>
            </Link>
            <span className="tooltip">Dashboard</span>
          </li>
{/*          <li>
            <Link to="/kalender">
              <i className="bx bx-calendar"></i>
              <span className="links_name">Kalender</span>
            </Link>
            <span className="tooltip">Kalender</span>
          </li>*/}
          <li>
            <Link to="/maschinen">
              <i className="bx bx-list-ul"></i>
              <span className="links_name">Maschinen</span>
            </Link>
            <span className="tooltip">Maschinen</span>
          </li>
{/*          <li>
            <a href="/statistik">
              <i className="bx bx-pie-chart-alt-2"></i>
              <span className="links_name">Statistik</span>
            </a>
            <span className="tooltip">Statistik</span>
          </li>*/}

        {/*  <li>
            <a href="#">
              <i className="bx bx-cog"></i>
              <span className="links_name">Einstellungen</span>
            </a>
            <span className="tooltip">Einstellungen</span>
          </li>*/}
          <Link to={`logout`}>
            <li className="profile">
              <div className="profile-details">
                <div className="name_job">
                  <div className="name">
                    <strong>Imboden Baumaschinen</strong>
                  </div>
                  <div className="job">Admin</div>
                </div>
              </div>
              <i className="bx bx-log-out" id="log_out"></i>
            </li>
          </Link>
        </ul>
      </div>
    </>
  );
}
