import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import listPlugin from "@fullcalendar/list";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import Modal from "react-bootstrap/Modal";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import {
  deleteVermietung,
  getAlleRessourcen,
  getAlleVermietungen,
  saveNeueVermietung,
  updateVermietungZeit,
} from "./../FunctionsAPI";
import interactionPlugin from "@fullcalendar/interaction";

import PDFDocument from "./PDFDocument";
import ModalConfirm from "./ModalConfirm";
import {editableInputTypes} from "@testing-library/user-event/dist/utils";
import {Form, FormLabel} from "react-bootstrap";

const DashCalendar = () => {
  const [events, setEvents] = useState([]);
  const [resources, setResources] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [secondModalOpen, setSecondModalOpen] = useState(false);
  const [switchKategorie, setSwitchKategorie] = useState(true);
  // Track the confirmation message => ModalConfirm
  const [message, setMessage] = useState("");
  const [editedStartDate, setEditedStartDate] = useState(null);
    const [editedEndDate, setEditedEndDate] = useState(null);
  const [displayResources, setDisplayResources] = useState(resources);

  useEffect(() => {
    // You can fetch events and resources data from an API here and set it to the state.
    getAlleVermietungen().then((anfragen) => {
      const resourcesArray = anfragen.map((vermietung) => ({
        id: vermietung.id_vermietungen_booked,
        title:
          vermietung.name +
          " " +
          vermietung.vorname +
          " (" +
          vermietung.seriennummer +
          ")",
        start: vermietung.startdatum,
        end: vermietung.enddatum,
        resourceId: vermietung.fk_maschine,
        maschinen_name: vermietung.name_maschine,
        statusId: vermietung.fk_status_hebebuehne,
        kundenId: vermietung.id_vermietungen,
        seriennummer: vermietung.seriennummer,
        name: vermietung.name,
        vorname: vermietung.vorname,
        hoehe: vermietung.hoehe,
        email: vermietung.email,
        kontostelle: vermietung.kontostelle,
        id_vermietungen: vermietung.id_vermietungen,
        backgroundColor: "#fbbb00",
        //backgroundColor:
        //  vermietung.status_vermietung_id == 1 ? "#F52727" : "#06de33",
        borderColor: "#fbbb00",
        resourceEditable: false,
      }));
      setEvents(resourcesArray);
    });

    /*getAllMachines().then((data) => {
      const resourcesArray1 = data
        .filter((machine) => machine.idKategorie == 1)
        .map((machine) => ({
          id: machine.idMaschine,
          title: machine.maschinen_name,
        }));
      setDisplayResources(resourcesArray1);
      setResources(resourcesArray1);
    });*/

    getAlleRessourcen().then((data) => {
      const alleRessourcen = data
        .filter((maschine) => maschine.typ == "Scherenbühne")
        .map((maschine) => ({
          id: maschine.id,
          title: maschine.name_maschine,
        }));
      setDisplayResources(alleRessourcen);
      setResources(alleRessourcen);
    });
  }, []);

  const handleEventAdd = ({ start, end, resource }) => {
    setSecondModalOpen(true);

    // Assuming you have an initialEvents array that contains existing events
    const newEvent = {
      title: "New Event",
      start: start,
      end: end,
      //resourceId: resource.id,
      maschinen_name: "test",
      statusId: 1,
      kundenId: 0,
      seriennummer: 34,
      name: "test",
      vorname: "test",
      hoehe: 25,
      email: "mail",
      kontostelle: 234,
      id_vermietungen: 0,
      backgroundColor: "#fbbb00",
      borderColor: "#fbbb00",
      resourceEditable: false,
    };

    console.log(events);

    // Combine the new event with the existing events
    setEvents((prevEvents) => [...prevEvents, newEvent]);

    console.log(events);

    //saveNeueVermietung(dataToSave).then((data) => console.log(data));

    //window.location.reload();
  };

  const changeHebebuehneTyp = () => {
    getAlleRessourcen().then((data) => {
      const resourcesArray = data
        .filter((maschine) => maschine.typ == "Teleskopbühne")
        .map((maschine) => ({
          id: maschine.id,
          title: maschine.name_maschine,
        }));
      console.log(resourcesArray);
      setSwitchKategorie(!switchKategorie);
      setDisplayResources(
        displayResources === resources ? resourcesArray : resources
      );
    });
  };

  const handleEventResizeStop = (event) => {
    const start = new Date(event.event.start);
    const end = new Date(event.event.end);
    const id = event.event.extendedProps.id_vermietungen;

    // Add 2 hours to the start and end dates
    start.setHours(start.getHours() + 2);
    end.setHours(end.getHours() + 2);

    console.log(end);

    const dataToSave = {
      startdatum: start,
      enddatum: end,
      id_vermietungen: id,
    };

    console.log(dataToSave);

    updateVermietungZeit(dataToSave).then((data) => console.log(data));

    //window.location.reload();
  };

  const handleEventClick = (info) => {

      console.log(info.event.extendedProps);
      setSelectedEvent({
        name: info.event.extendedProps.name,
        vorname: info.event.extendedProps.vorname,
        hoehe: info.event.extendedProps.hoehe,
        kunden_id: info.event.extendedProps.kundenId,
        maschinen_name: info.event.extendedProps.maschinen_name,
        status_id: info.event.extendedProps.statusId,
        seriennummer: info.event.extendedProps.seriennummer,
        kontostelle: info.event.extendedProps.kontostelle,
        email: info.event.extendedProps.email,
        id_vermietungen: info.event.extendedProps.id_vermietungen,
        startdatum: info.event.start,
        enddatum: info.event.end,
      });
      setEditedEndDate(info.event.end);
      setEditedStartDate(info.event.start);

    setModalOpen(true);
  };


  const deleteLastItem = () => {
    const newArray = events.slice(0, -1);
    setEvents(newArray);
  };

  const handleCloseModal = () => {
    deleteLastItem();
    setSecondModalOpen(false);
  };

  const handleDeleteVermietung = (data) => {
    const dataToDelete = {
      id_vermietungen: data,
    };

    deleteVermietung(dataToDelete);

    // Filter out the event with the specified id_vermietungen
    /*const updatedEvents = events.filter(
      (event) => event.id_vermietungen !== data
    );*/

    // Update the state with the filtered events
    //setEvents(updatedEvents);

    window.location.reload();

    //setModalOpen(false);
  };

  // pass this function to ModalConfirm => set message to ""
  // for re-opening ModalConfirm
  const handleMessage = (message) => (e) => {
    setMessage(message);
    setModalOpen(false);
  }

  const handleStartDateChange = (event) => {
    setEditedStartDate(new Date(event.target.value));

    console.log(editedStartDate);
  };

    const handleEndDateChange = (event) => {
    setEditedEndDate(new Date(event.target.value));

    console.log(editedEndDate);
    }

  const updateDates = (id) => (e) => {
    if (editedStartDate === null) {
      setEditedStartDate(selectedEvent.startdatum);
    } else{
      editedStartDate.setHours(editedStartDate.getHours() + 2);
    }
    if (editedEndDate === null) {
      setEditedEndDate(selectedEvent.enddatum);
    } else {
        editedEndDate.setHours(editedEndDate.getHours() + 2);
    }

    const dataToSave = {
          startdatum: editedStartDate,
          enddatum: editedEndDate,
          id_vermietungen: id,
      };

      console.log(dataToSave);
     // TODO:
      updateVermietungZeit(dataToSave).then((data) =>
      window.location.reload());


  }

  return (
    <>
      <FullCalendar
        plugins={[
          dayGridPlugin,
          listPlugin,
          timeGridPlugin,
          resourceTimelinePlugin,
          interactionPlugin,
        ]}
        customButtons={{
          myCustomButton: {
            text: switchKategorie
              ? "Wechseln zu Teleskopbühnen"
              : "Wechseln zu Scherenbühnen",
            click: function () {
              changeHebebuehneTyp();
            },
          },
        }}
        headerToolbar={{
          left: "prev,next today myCustomButton",
          center: "title",
          //right: props.options,
          right: "resourceTimeline,dayGridMonth,listWeek",
        }}
        buttonText={{
          today: "heute",
          month: "Monat",
          week: "Woche",
          day: "Tag",
          list: "Woche",
          resourceTimeline: "Maschinen",
        }}
        selectable="true"
        editable="true"
        height="auto"
        slotDuration="1:00:00"
        slotMinTime="06:00:00"
        slotMaxTime="18:00:00"
        businessHours={{
          // days of week. an array of zero-based day of week integers (0=Sunday)
          daysOfWeek: [1, 2, 3, 4, 5], // Monday - Thursday
          startTime: "06:00", // a start time (10am in this example)
          endTime: "18:00", // an end time (6pm in this example)
        }}
        initialView="resourceTimeline"
        events={events}
        resources={displayResources}
        resourceAreaWidth="15%"
        locale="de"
        nowIndicator="true"
        eventDisplay="block"
        select={handleEventAdd}
        eventDrop={handleEventResizeStop}
        drop={handleEventResizeStop}
        eventResize={handleEventResizeStop}
        eventClick={handleEventClick}
      />
      {selectedEvent === null ? (
        ""
      ) : (
        <Modal show={modalOpen} onHide={() => setModalOpen(false)}>
          <Modal.Header closeButton>
            <Modal.Title>
              {selectedEvent.name +
                " " +
                selectedEvent.vorname +
                " (" +
                selectedEvent.seriennummer +
                ")"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ListGroup>
              <ListGroup.Item>
                <strong>Name:</strong> {selectedEvent.name}
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>Vorname:</strong> {selectedEvent.vorname}
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>Von:</strong>  {selectedEvent.startdatum.toLocaleString('de-CH', {
                year: 'numeric',
                month: 'short',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
              })}
                <input type="datetime-local" style={{ marginLeft: '40px' }} onChange={handleStartDateChange} />
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>Bis:</strong>  {selectedEvent.enddatum.toLocaleString('de-CH', {
                year: 'numeric',
                month: 'short',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
              })}
                <input type="datetime-local" style={{ marginLeft: '40px' }} onChange={handleEndDateChange} />
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>Maschine:</strong> {selectedEvent.maschinen_name}
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>Seriennummer:</strong> {selectedEvent.seriennummer}
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>Kontostelle:</strong> {selectedEvent.kontostelle}
              </ListGroup.Item>
            </ListGroup>
          </Modal.Body>
          <Modal.Footer className="speciale">
            <div>
              <Button
                variant="danger"
                onClick={handleMessage("Wollen Sie die Reservierung wirklich löschen?")}
              >
                <i className="bx bx-trash"></i>
              </Button>
            </div>

              <Button
                  variant="primary"
                  onClick={updateDates(selectedEvent.id_vermietungen)}
              >
               Speichern
              </Button>

            <div>
              <PDFDocument data={selectedEvent} />
              {/*<Button
                className="speciale"
                variant="success"
                onClick={() => {
                  setModalOpen(false);
                  setSelectedEvent(null);
                }}
              >
                <i class="bx bx-save"></i>
              </Button>*/}
            </div>
          </Modal.Footer>
        </Modal>
      )}
      {secondModalOpen && (
        <Modal
          show={secondModalOpen}
          animation={false}
          centered
          onHide={handleCloseModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Neue Reservierung</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form >
                <Form.Group className="mb-3" controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" name="name" required/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="vorname">
                    <Form.Label>Vorname</Form.Label>
                    <Form.Control type="text" name="vorname" required/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" name="email" required/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="kontostelle">
                    <Form.Label>Kontostelle</Form.Label>
                    <Form.Control type="text" name="kontostelle" required/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="startdatum">
                    <Form.Label>Von</Form.Label>
                    <Form.Control type="datetime-local" name="startdatum" required/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="enddatum">
                    <Form.Label>Bis</Form.Label>
                    <Form.Control type="datetime-local" name="enddatum" required/>
                </Form.Group>

            </Form>
          </Modal.Body>
          <Modal.Footer>

            <Button
              variant="primary"
              onClick={() => {
                handleCloseModal();
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {message  && (
          <ModalConfirm email={selectedEvent.email} idVermietung={selectedEvent.id_vermietungen} text={message} buttonText={"Bestätigen"} handleMessage={handleMessage()}
          />
      )}
    </>
  );
};

export default DashCalendar;
